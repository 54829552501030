var app = {};

app.init = function () {
  // app.tour();
  app.video();
  app.videopreview();
  app.dropdown();
  app.hamburger();
  app.galleryModal();
  app.lyrics();
  app.accessibleDropdown();
  app.slider();
};

app.tour = function () {
  const tourContainer = $("#tourDates");
  $.ajax({
    type: "GET",
    url: "https://tour.jacksonbrowne.com/api/liveevents/jacksonbrowne",
    success: function (result) {
      parseData(result);
    },
  });

  function parseData(responseData) {
    const events = responseData;
    let items = "";

    if (events.length === 0) $("#noDates").show();

    for (let i = 0; i < 5; i++) {
      let event = events[i].LiveEvent;

      // Get the Date and put it in a <div>
      let date = formatDate(
        new Date(event.EventDate.replace(/-/g, "/").replace(/T.+/, ""))
      );
      let dateWrap = $("<div>");
      dateWrap.addClass("date");
      dateWrap.append(date);

      // Get the Venue and Location Details and put it in a <div>
      //venue
      let venue = event.Venue;
      let venueWrap = $("<div>");
      venueWrap.addClass("venue");
      let venueLink = $("<a>");
      venueLink.attr("href", event.VenueUrl);
      venueLink.attr("target", "_blank");
      venueLink.addClass("location");
      venueLink.append(venue);
      venueWrap.append(venueLink);
      //city
      let city = event.City;
      let region = event.Region;
      let country = event.Country;

      let note = event.EventListNote;
      let noteWrap = $('<p class="note">');
      noteWrap.append(note);
      let cityWrap = $('<span class="city">');
      cityWrap.append(city, `, `);
      if (region) cityWrap.append(region, `, `);
      cityWrap.append(country);

      //wrap
      let location = $("<div>");
      location.addClass("location-and-note");

      location.append(cityWrap, noteWrap);

      //Get Facebook RSVP and put it in a link
      let fbText;
      let fbEvent;
      if (event.FacebookEventId)
        (fbText =
          '<svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-square" class="svg-inline--fa fa-facebook-square fa-w-14" role="img" aria-label="Facebook" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"></path></svg> RSVP'),
          (fbEvent =
            "https://www.facebook.com/events/" + event.FacebookEventId);
      let fbLink = $("<a role='button'>");
      fbLink.attr("href", fbEvent);
      fbLink.attr("target", "_blank");
      fbLink.append(fbText);

      // if facebook is included in this tour widget, add fbButton to listItem.append below
      let fbButton = $('<div class="RSVP">');
      if (event.FacebookEventId) fbButton.append(fbLink);

      // Get the Ticket Details and put it in a <button>
      let ticketText;
      let ticketEvent;

      if (event.ExternalTicketUrl)
        (ticketText = "Buy Tickets"), (ticketEvent = event.ExternalTicketUrl);

      let ticketLink = $("<a role='button'>");

      ticketLink.attr("href", ticketEvent);
      ticketLink.attr("target", "_blank");
      ticketLink.append(ticketText);

      // if ticket purchase button is included in this tour widget, add ticketButton to listItem.append below
      let ticketButton = $('<div class="moreTitle"><a href="https://tour.JacksonBrowne.com/">Buy Tickets</a></div>');
      // if (event.ExternalTicketUrl) ticketButton.append(ticketLink);

      let listItem = $("<li>");
      listItem.addClass("tour-date");
      listItem.append(dateWrap, venueWrap, location, ticketButton);

      //NOTE listItem is an object so I accessed the property outerHTML to get the <li> html in string form
      let listItemHtml = listItem[0].outerHTML;
      items += listItemHtml;
    }
    addToPage(items);
  }

  function addToPage(items) {
    tourContainer.append(items);
  }

  function formatDate(date) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    let day = date.getDate();
    let dayString = ("0" + day).slice(-2);
    let monthIndex = date.getMonth();
    let year = date.getFullYear();

    return (
      '<span class="month">' +
      monthNames[monthIndex] +
      " </span>" +
      '<span class="day">' +
      dayString +
      '</span><span class="comma">,</span> ' +
      '<span class="year"> ' +
      year +
      "</span>"
    );
  }
};

//Youtube Video Embed
app.video = function () {
  var div,
    n,
    v = document.getElementsByClassName("youtube-player-single");
  for (n = 0; n < v.length; n++) {
    div = document.createElement("button");
    div.setAttribute("data-id", v[n].dataset.id);
    div.setAttribute("data-title", v[n].dataset.title);
    div.setAttribute("title", v[n].dataset.title);
    div.setAttribute("class", "video-player-button");
    div.innerHTML = videoThumb(v[n].dataset.id);
    div.onclick = videoIframe;
    v[n].appendChild(div);
  }

  function videoThumb(id) {
    let imgAlt = v[n].dataset.title;
    var thumb = `<img src="https://i.ytimg.com/vi/ID/hqdefault.jpg" alt="${imgAlt}" width="480" height="360" loading="lazy">`;
    let play = '<div class="play"></div>';
    return thumb.replace("ID", id) + play;
  }

  function videoIframe() {
    var iframe = document.createElement("iframe");
    var embed = "https://www.youtube.com/embed/ID?autoplay=1";
    iframe.setAttribute("src", embed.replace("ID", this.dataset.id));
    iframe.setAttribute("title", this.dataset.title);
    iframe.setAttribute("frameborder", "0");
    iframe.setAttribute("allowfullscreen", "1");
    iframe.setAttribute("width", "480");
    iframe.setAttribute("height", "269");
    iframe.setAttribute("loading", "lazy");
    this.parentNode.replaceChild(iframe, this);
  }
};

// variation of video
// function
// for landing pages
app.videopreview = function () {
  var div,
    n,
    v = document.getElementsByClassName("youtube-player-preview");
  for (n = 0; n < v.length; n++) {
    div = document.createElement("div");
    div.setAttribute("data-id", v[n].dataset.id);
    div.setAttribute("data-title", v[n].dataset.title);
    div.innerHTML = videoThumb(v[n].dataset.id);
    v[n].appendChild(div);
  }

  function videoThumb(id) {
    let imgAlt = v[n].dataset.title;
    var thumb = `<img src="https://i.ytimg.com/vi/ID/hqdefault.jpg" alt="Image of video ${imgAlt}" width="480" height="360" loading="lazy">`;
    return thumb.replace("ID", id);
  }
};

//Dropdowns
app.dropdown = function () {
  $(".dropdownContent").hide();
  $(".dropdown").click(function () {
    $(this).siblings(".dropdownContent").slideToggle();
    $(this).find(".caret").toggleClass("fa-caret-up fa-caret-down");
  });
};

//Mobile Hamburger Menu
app.hamburger = function () {
  $(".nav-icon-container").on("click", function () {
    let isNavOpen = $(".body-with-open-nav");

    if (isNavOpen.length > 0) {
      $(".header-mobile-wrapper").attr("aria-hidden", "true");
    } else {
      $(".header-mobile-wrapper").attr("aria-hidden", "false");
    }
    $(".nav-icon").toggleClass("open");
    $("body").toggleClass("body-with-open-nav");
  });

  // Tabbing through last menu item closes the modal
  $("#menu-primary-navigation li:last-child a").focusout(function () {
    let width = $(window).width();
    if (width < 993) {
      $(".header-mobile-wrapper").attr("aria-expanded", "false");
      $("body").removeClass("body-with-open-nav");
      $(".nav-icon").toggleClass("open");
      $(".nav-icon-container").focus();
    }
  });

  // Pressing on the escape key closes the mobile menu
  $(document).keyup(function (e) {
    if (e.key === "Escape") {
      if ($("body").hasClass("body-with-open-nav")) {
        $(".header-mobile-wrapper").attr("aria-expanded", "false");
        $("body").removeClass("body-with-open-nav");
        $(".nav-icon").toggleClass("open");
        $(".nav-icon-container").focus();
      }
    }
  });
};

app.lyrics = function () {
  $(".lyrics-header").on("click", function () {
    var currentSong = $(this).parent().parent(".each-track");
    $(currentSong).siblings().removeClass("lyrics-showing");
    $(currentSong)
      .siblings()
      .find(".lyrics-header")
      .attr("aria-expanded", "false");

    if (currentSong.hasClass("lyrics-showing")) {
      $(this).attr("aria-expanded", "false");
      currentSong.removeClass("lyrics-showing");
    } else {
      $(this).attr("aria-expanded", "true");
      currentSong.addClass("lyrics-showing");
    }
  });
};

//slider and modal as used on a single gallery page. Does not autoadvance.
app.galleryModal = function () {
  const imageModal = document.querySelector(".gallery-modal");
  const closeButton = document.querySelector(".gallery-modal-close");

  let previousActiveElement;

  if (imageModal) {
    $(".preview-link").focus(function () {
      if ($(".gallery-modal").hasClass("modal-showing")) {
        closeImageModal();
      }
    });

    let slideIndex = null;

    $(".preview-link").on("click", function () {
      slideIndex = $(this).data("index");
      showSlides((slideIndex += 1));
      previousActiveElement = document.activeElement;
      imageModal.classList.add("modal-showing");
      $("body").addClass("body-with-open-modal");
      $(imageModal).attr("aria-hidden", "false");
      closeButton.focus();
      return previousActiveElement;
    });

    function plusSlides(n) {
      showSlides((slideIndex += n));
    }

    $(".slider-prev").click(function () {
      plusSlides(-1);
    });
    $(".slider-next").click(function () {
      plusSlides(1);
    });

    function showSlides(n) {
      let i;
      let x = document.getElementsByClassName("one-modal-img");
      if (n > x.length) {
        slideIndex = 1;
      }
      if (n < 1) {
        slideIndex = x.length;
      }
      for (i = 0; i < x.length; i++) {
        x[i].style.display = "none";
      }
      x[slideIndex - 1].style.display = "block";
    }

    closeButton.addEventListener("click", closeImageModal);

    //ESCAPE BUTTON CLOSES MODAL
    $(document).keydown(function (event) {
      if (event.keyCode == 27) {
        closeImageModal();
      }
    });

    function closeImageModal() {
      imageModal.classList.remove("modal-showing");
      $("body").removeClass("body-with-open-modal");
      $(imageModal).attr("aria-hidden", "true");
      previousActiveElement.focus();
    }
  }
};

app.accessibleDropdown = function () {
  $(".menu-item-has-children>a")
    .attr("aria-haspopup", "true")
    .attr("aria-expanded", "false");

  $(".menu-item-has-children").on("mouseenter focusin", function () {
    $(".menu-item-has-children>a").attr("aria-expanded", "true");
  });

  $(".menu-item-has-children").on("mouseleave focusout", function () {
    $(".menu-item-has-children>a").attr("aria-expanded", "false");
  });

  $(".discography-toggle").on("click", function () {
    $(this).next(".discography-button-list").toggleClass("links-showing");
    var isThisAlreadyExpanded = $(this).attr("aria-expanded");

    if (isThisAlreadyExpanded == "false") {
      $(this).attr("aria-expanded", "true");
    } else {
      $(this).attr("aria-expanded", "false");
    }
  });
};

// default slider with auto advance
app.slider = function () {
  if ($(".slider").length) {
    var slideIndex = 0;
    showSlides(slideIndex);

    function plusSlides(n) {
      showSlides((slideIndex += n));
    }

    function autoAdvance() {
      plusSlides(1);
    }

    autoAdvance();

    var timerId = setInterval(autoAdvance, 9000);

    $(".slider-prev").click(function () {
      plusSlides(-1);
      clearInterval(timerId);
    });
    $(".slider-next").click(function () {
      plusSlides(1);
      clearInterval(timerId);
    });
    $(".pause-slideshow").on("click", function () {
      clearInterval(timerId);
      $(this).css("display", "none");
      $(".play-slideshow").css("display", "flex");
    });
    $(".play-slideshow").on("click", function () {
      plusSlides(1);
      timerId = setInterval(autoAdvance, 9000);
      $(this).css("display", "none");
      $(".pause-slideshow").css("display", "flex");
    });

    function showSlides(n) {
      var i;
      var x = document.getElementsByClassName("hero-slides");
      if (n > x.length) {
        slideIndex = 1;
      }
      if (n < 1) {
        slideIndex = x.length;
      }
      for (i = 0; i < x.length; i++) {
        x[i].style.opacity = "0";
        x[i].style.zIndex = "0";
      }
      x[slideIndex - 1].style.opacity = "1";
      x[slideIndex - 1].style.zIndex = "1";
    }

    //If only one slide exists, don't show the prev + next + pause buttons
    const slides = document.querySelectorAll(".hero-slides");
    if (slides.length < 2) {
      $(".slider-button").css("display", "none");
    }
  }
};

$(document).ready(function () {
  //call all the app functions
  app.init();

});